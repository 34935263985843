<template>
    <div :class="{ '--has-borders': hasBorders }" class="navigable-page-sections">
        <div class="navigable-page-sections__wrapper">
            <slot name="sections">
                <template v-for="section in sections">
                    <NavigablePageSection
                        v-if="section.menuId"
                        :key="section.menuId"
                        :sectionId="section.menuId"
                        ref="pageSections"
                    >
                        <slot name="section" v-bind="section"></slot>
                    </NavigablePageSection>
                    <slot v-else name="section" v-bind="section"></slot>
                </template>
            </slot>
        </div>
    </div>
</template>

<script>
import NavigablePageSection from '@module/NavigablePageSection';

export default {
    props: {
        sections: {
            type: Array,
            default: () => [],
        },
        hasBorders: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NavigablePageSection,
    },
};
</script>
