<template>
    <nav :class="`--variation-${variation}`" class="navigable-page-menu" ref="navPageMenu">
        <div class="navigable-page-menu__wrapper" ref="navItemsWrapper">
            <div class="navigable-page-menu__back" @click="moveMenu('back')">
                <Icon icon="chevronLeft" />
            </div>

            <div
                class="navigable-page-menu__list-wrapper"
                :class="variation === 'justify-left' ? 'wrapper' : ''"
                ref="navItemsList"
            >
                <ul class="navigable-page-menu__list">
                    <li class="navigable-page-menu__spacer-item" ref="spacerItemLeft" />
                    <li
                        class="navigable-page-menu__item"
                        v-for="item in navigableSections"
                        :key="item.menuId"
                        ref="navItems"
                    >
                        <a
                            class="small-text"
                            :href="`#${item.menuId}`"
                            @click="handleMenuItemClicked(item.menuId, $event)"
                            v-html="Array.isArray(item.menuLabel) ? item.menuLabel[0] : item.menuLabel"
                        ></a>
                    </li>
                    <li class="navigable-page-menu__spacer-item" ref="spacerItemRight" />
                </ul>
            </div>

            <div class="navigable-page-menu__forward" @click="moveMenu('forward')">
                <Icon icon="chevronRight" />
            </div>

            <div class="navigable-page-menu__progress-marker" />
            <div v-if="variation === 'vertical'" class="navigable-page-menu__progress-track" />
        </div>
    </nav>
</template>

<script>
import Icon from '@module/Icon';

//FIXME: remove the isArray check above when section titles no longer return as arrays

export default {
    props: {
        navigableSections: {
            type: Array,
            required: true,
        },
        variation: {
            type: String,
            default: 'subnav',
            validator: (value) => {
                return ['subnav', 'vertical', 'justify-left'].includes(value);
            },
        },
    },
    components: {
        Icon,
    },
};
</script>
