<template>
    <div class="navigable-page-section">
        <div class="navigable-page-section__scroll-anchor" :id="sectionId" ref="scrollAnchors" />
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        sectionId: {
            type: String,
            required: true,
        },
    },
};
</script>
