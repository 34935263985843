import debounce from 'lodash.debounce';
import parsePath from '@mixin/parsePath';

export default function navigablePage({
    sectionsPath,
    sectionIdProperty = 'id',
    sectionLabelProperty = 'title',
    scrollBaseline = false,
    variation = 'horizontal',
} = {}) {
    return {
        mixins: [parsePath],
        data: () => ({
            navHeight: 0,
            scrollTop: 0,
            prevScrollTop: 0,
            scrollHeight: 0,
            windowHeight: 0,
            windowWidth: 0,
            windowMiddle: 0,
            navItemsPositions: [],
            tierGroupPositions: [],
            activeTierGroupIndex: 0,
            firstGroupTop: 0,
            markerProgress: 0,
            totalProgress: 0,
            overflowOffset: 0,
            itemOverScrollBaseline: false,
            isAnimationLoopDisabled: false,
        }),

        elementReferences: null,

        computed: {
            pageSections() {
                const sections = sectionsPath && this.parsePath(sectionsPath);
                if (sections?.length) {
                    return sections.map((section) => {
                        //FIXME: remove the isArray check when section titles no longer return as arrays
                        const sectionTitle = Array.isArray(section[sectionIdProperty])
                            ? section[sectionIdProperty][0]
                            : section[sectionIdProperty];
                        const sectionTitleId = section['title_id'];
                        return {
                            ...section,
                            isNavigable:
                                section.showInSubnavigation !== undefined
                                    ? section.showInSubnavigation
                                    : !section.hideFromNavigation && !!section[sectionLabelProperty],
                            menuId: sectionTitleId
                                ? sectionTitleId?.toLowerCase().replaceAll(' ', '-').replaceAll(/['"]/g, '')
                                : sectionTitle?.toLowerCase().replaceAll(' ', '-').replaceAll(/['"]/g, ''),
                            menuLabel: section[sectionLabelProperty],
                        };
                    });
                } else {
                    return [];
                }
            },
            navigableSections() {
                if (this.pageSections.length) {
                    return this.pageSections.filter((section) => section.isNavigable);
                } else {
                    return [];
                }
            },

            scrollMiddle() {
                return this.scrollTop + this.resolvedScrollBaseline; // this.windowMiddle
            },

            scrollBottom() {
                return this.scrollTop + this.windowHeight;
            },

            resolvedScrollBaseline() {
                return scrollBaseline || this.resolvedAnchorOffset;
            },

            resolvedAnchorOffset() {
                return scrollBaseline ? scrollBaseline : this.navHeight;
            },

            activeGroupProgress() {
                const activeGroupPosition = this.tierGroupPositions[this.activeTierGroupIndex];
                if (!activeGroupPosition) {
                    return 0;
                }

                const { top, height, offsetTop } = activeGroupPosition;
                const { resolvedScrollBaseline, scrollTop } = this;

                const scrollDiff =
                    variation === 'vertical'
                        ? scrollTop + resolvedScrollBaseline - offsetTop
                        : scrollTop + resolvedScrollBaseline - top;

                const progress = scrollDiff / (height / 2);
                return progress < 0 ? false : progress;
            },

            minProgress() {
                const firstGroupNavItem = this.navItemsPositions[0];
                return firstGroupNavItem?.distanceFromStart || 0;
            },

            maxProgress() {
                const lastGroupNavItem = this.navItemsPositions[this.navItemsPositions.length - 1];
                const distanceFromLeft =
                    this.$options.elementReferences && variation === 'justify-left'
                        ? this.$options.elementReferences.navItemsListRef.getBoundingClientRect().left
                        : 0;
                return lastGroupNavItem
                    ? lastGroupNavItem.distanceFromStart + lastGroupNavItem.size + distanceFromLeft
                    : 0;
            },

            listOverflow() {
                if (variation === 'vertical') {
                    return 0;
                }
                return this.maxProgress - this.windowWidth;
            },

            navigablePageStyles() {
                const { resolvedAnchorOffset, maxProgress, markerProgress, overflowOffset } = this;

                return {
                    '--progress-marker-width': `${markerProgress}px`,
                    '--marker-progress': `0px`,
                    '--max-progress': `${maxProgress}px`,
                    '--scroll-anchor-offset': `${resolvedAnchorOffset * -1}px`,
                    '--overflow-offset': `-${overflowOffset}px`,
                };
            },
        },

        methods: {
            updateElements() {
                const sections = this.$refs?.sections;
                const sectionsRef = sections?.length ? sections[0] : sections;
                const sectionEls = sectionsRef?.$refs?.pageSections || this.$refs?.pageSections;
                const menu = this.$refs?.menu;
                const menuRef = menu?.length ? menu[0] : menu;

                this.$options.elementReferences = {
                    sectionsRef,
                    pageSectionsEls: sectionEls && sectionEls.map((section) => section.$el),
                    menuRef,
                    navItemsListRef: menuRef?.$refs?.navItemsList,
                    navPageMenuRef: menuRef?.$refs?.navPageMenu,
                    navItemRefs: menuRef?.$refs?.navItems,
                    navSpacerRefs: [menuRef?.$refs?.spacerItemLeft, menuRef?.$refs?.spacerItemRight].filter(Boolean),
                };
            },

            cacheDomDimensions: debounce(function () {
                this.scrollTop = document.scrollingElement.scrollTop;
                this.scrollHeight = document.scrollingElement.scrollHeight;
                this.windowHeight = window.innerHeight;
                this.windowWidth = window.innerWidth;
                this.windowMiddle = this.windowHeight / 2;
                this.yearPositions = [];
                this.navItemsPositions = [];
                this.tierGroupPositions = [];

                this.$options.elementReferences.pageSectionsEls &&
                    this.$options.elementReferences.pageSectionsEls.forEach((tierGroupEl, index) => {
                        const domRect = tierGroupEl.getBoundingClientRect();
                        const scrollAnchor = tierGroupEl.querySelector('.navigable-page-section__scroll-anchor');
                        const navItemEl =
                            this.$options.elementReferences.navItemRefs &&
                            this.$options.elementReferences.navItemRefs.find(
                                (navItemEl) => navItemEl.firstChild.hash === `#${scrollAnchor.id}`,
                            );
                        const navItemAnchor = navItemEl && navItemEl.firstChild;
                        const tierGroupPosition = {
                            rectTop: domRect.top,
                            top: domRect.top + this.scrollTop,
                            anchorTop: domRect.top + this.scrollTop + scrollAnchor.offsetTop,
                            bottom: domRect.bottom + this.scrollTop,
                            height: domRect.height,
                            el: tierGroupEl,
                            offsetTop: tierGroupEl.offsetTop,
                            anchorId: scrollAnchor.id,
                            scrollAnchor,
                            navItemAnchor,
                        };

                        if (index === 0) {
                            this.firstGroupTop = tierGroupPosition.top;
                        }

                        this.tierGroupPositions.push(tierGroupPosition);
                    });

                const [spacerItemLeft, spacerItemRight] = this.$options.elementReferences.navSpacerRefs;
                this.$options.elementReferences.navItemRefs &&
                    [spacerItemLeft, ...this.$options.elementReferences.navItemRefs, spacerItemRight].forEach(
                        (navItem) => {
                            const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = navItem;
                            const distanceFromStart = variation === 'vertical' ? offsetTop : offsetLeft;
                            const size = variation === 'vertical' ? offsetHeight : offsetWidth;

                            this.navItemsPositions.push({
                                navItem,
                                offsetTop,
                                distanceFromStart,
                                size,
                                offsetHeight,
                                navItemCenter: size / 2,
                                offsetMiddle: distanceFromStart + size / 2,
                            });
                        },
                    );

                this.navHeight = parseInt(
                    getComputedStyle(document.body).getPropertyValue('--nav-height').replace('px', ''),
                );
                this.toggleMenuScrollButtons();
                this.processSections();
            }, 500),

            processSections() {
                const { tierGroupPositions, scrollMiddle } = this;
                let itemOverScrollBaseline = false;

                tierGroupPositions.forEach((tierGroupPosition, index) => {
                    const { el, top, bottom, navItemAnchor } = tierGroupPosition;
                    const isActive =
                        (index > 0 ? top <= scrollMiddle : true) &&
                        (index < tierGroupPositions.length - 1 ? bottom > scrollMiddle : true);
                    itemOverScrollBaseline = itemOverScrollBaseline || (top <= scrollMiddle && bottom >= scrollMiddle);
                    el.classList.toggle('--is-active', isActive);
                    navItemAnchor?.classList.toggle('--is-active', isActive);

                    if (isActive) {
                        this.activeTierGroupIndex = index;
                    }
                });

                this.$options.elementReferences.sectionsRef?.$el?.classList.toggle(
                    '--item-over-scroll-midline',
                    itemOverScrollBaseline,
                );
                this.updateProgresss();
                this.toggleMenuScrollButtons();
            },

            updateProgresss() {
                const {
                    activeGroupProgress,
                    activeTierGroupIndex,
                    tierGroupPositions,
                    navItemsPositions,
                    maxProgress,
                    minProgress,
                    listOverflow,
                } = this;
                const activeGroupNavItem = navItemsPositions[activeTierGroupIndex + 1]; // Add 1 to account for the left spacer
                const isLastTierGroup = activeTierGroupIndex === tierGroupPositions.length - 1;
                if (!activeGroupNavItem) {
                    return;
                }

                const { distanceFromStart, navItemCenter } = activeGroupNavItem;

                const distanceFromLeft =
                    this.$options.elementReferences && variation === 'justify-left'
                        ? this.$options.elementReferences.navItemsListRef.getBoundingClientRect().left
                        : 0;

                const totalWidth = distanceFromStart + distanceFromLeft;
                let markerProgress;
                if (isLastTierGroup) {
                    const rightSpacer = navItemsPositions[navItemsPositions.length - 1];
                    markerProgress = activeGroupProgress
                        ? activeGroupProgress * ((activeGroupNavItem.size + rightSpacer.navItemCenter) / 2) + totalWidth
                        : 0;
                } else {
                    markerProgress = activeGroupProgress ? activeGroupProgress * navItemCenter + totalWidth : 0;
                }
                markerProgress = Math.max(minProgress, markerProgress);
                markerProgress = Math.min(maxProgress, markerProgress);
                const totalProgress = markerProgress / maxProgress;
                const targetOffset = totalProgress * listOverflow;

                this.markerProgress = markerProgress;
                this.overflowOffset = targetOffset;
                this.totalProgress = totalProgress;
            },

            toggleMenuScrollButtons() {
                this.$options.elementReferences.navPageMenuRef?.classList.toggle(
                    '--show-back-button',
                    this.listOverflow > 0 && this.overflowOffset > 0,
                );
                this.$options.elementReferences.navPageMenuRef?.classList.toggle(
                    '--show-forward-button',
                    this.listOverflow > 0 && this.overflowOffset < this.listOverflow,
                );
            },

            animationFrameTick() {
                this.prevScrollTop = this.scrollTop;
                this.scrollTop = document.scrollingElement.scrollTop;

                if (this.scrollTop !== this.prevScrollTop) {
                    this.isAnimationLoopDisabled = false;
                }
                if (!this.isAnimationLoopDisabled) {
                    this.processSections();
                }
                if (this._isMounted && !this._isDestroyed) {
                    this.animationFrameId = requestAnimationFrame(() => this.animationFrameTick());
                } else {
                    this.cleanup();
                }
            },

            cleanup() {
                if (this.animationFrameId) {
                    cancelAnimationFrame(this.animationFrameId);
                }

                this.resizeObservers.forEach((observer) => observer.disconnect());
            },

            setupResizeObservers() {
                const rootElObserver = new ResizeObserver(this.cacheDomDimensions.bind(this));
                const docScrollerObserver = new ResizeObserver(this.cacheDomDimensions.bind(this));

                this.resizeObservers = [];

                this.resizeObservers.push(rootElObserver);
                this.resizeObservers.push(docScrollerObserver);

                rootElObserver.observe(this.$el);
                docScrollerObserver.observe(document.scrollingElement);
            },

            setupMenuEventHandlers() {
                const menuComponent = this.$options.elementReferences?.menuRef;
                if (menuComponent) {
                    menuComponent.moveMenu = (direction) => {
                        this.isAnimationLoopDisabled = true;
                        this.overflowOffset =
                            direction === 'forward' ? this.overflowOffset + 100 : this.overflowOffset - 100;
                        this.toggleMenuScrollButtons();
                    };
                    menuComponent.handleMenuItemClicked = (id, e) => {
                        e.preventDefault();
                        history.replaceState(undefined, undefined, `#${id}`);
                        this.scrollToSection(id);
                    };
                }
            },

            scrollToSection(sectionId) {
                const tierGroupPosition = this.tierGroupPositions.find(
                    (tierGroupPosition) => tierGroupPosition.anchorId === sectionId,
                );

                if (tierGroupPosition) {
                    const scrollDestination = tierGroupPosition.scrollAnchor.offsetTop + tierGroupPosition.top;

                    const scrollDirectionOffset =
                        this.scrollTop > scrollDestination
                            ? this.$options.elementReferences.navPageMenuRef?.clientHeight + 50
                            : 0;
                    document.scrollingElement.scrollTo({
                        top: scrollDestination - scrollDirectionOffset + 1,
                        behavior: 'smooth',
                    });
                }
            },

            scrollToCurrentSection() {
                const { hash } = window.location;
                if (hash) {
                    const sectionId = hash.replace('#', '');
                    this.scrollToSection(sectionId);
                }
            },

            setupNavigation() {
                this.updateElements();
                this.setupResizeObservers();
                this.cacheDomDimensions();
                this.animationFrameTick();
                this.setupMenuEventHandlers();
                // Not ideal, but this needs to be delayed to allow for the caching mechanism above,
                // and for any components that need to set the --nav-anchor-offset css variable
                setTimeout(() => this.scrollToCurrentSection(), 2000);
            },

            resetNavigation() {
                this.cleanup();
                this.setupNavigation();
            },
        },

        mounted() {
            this.setupNavigation();
        },

        beforeDestroy() {
            this.cleanup();
        },
    };
}
